<template>
    <div>
        <div v-if='deleteCollaborator'>
            <!-- CONFIRM DELETE -->
            <ConfirmDelete :show="collabDialog" :message="deleteMessage" :deleting="status.deleting" @cancel="deleteDeclined()" @confirmed="deleteConfirmed()" />
        </div>
        <div v-else>
            <v-dialog
                :value="collabDialog"
                max-width="520"
                persistent
                no-click-animation
            >
                <v-card>
                    <v-card-title>
                        <span class="font-weight-bold">Assigned Collaborators</span>
                        <v-btn @click="close()" icon absolute right>
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <preloader v-if="waitingUsers"/>
                    <v-card-text class="pt-3">
                        <div class="d-inline-block mb-2" :class="enableSelect" v-for="user in unique" :key="user.id">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div class="d-inline" v-on="on">
                                        <v-scale-transition origin="center center 0">
                                        <div>
                                            <UserPhoto :size="35" photoSize="thumb" :id="user.userid" :enableSelection="selectingUser" @userSelect="selectedUser(user)" />
                                            <div :ref="`${user.userid}`" class="dark rounded-circle mt-n9 d-none" style="position: absolute; z-index: 999; opacity: 0.9">
                                                <v-icon
                                                large
                                                dense
                                                color="green accent-3"
                                                @click="unselectUser(user)"
                                                >
                                                mdi-checkbox-marked-circle-outline
                                                </v-icon>
                                            </div>
                                        </div>
                                        </v-scale-transition>
                                    </div>
                                </template>
                                <span class="caption"><span class="text-capitalize">{{ user.collabRole }}</span>: {{ user.fullName }}</span>
                            </v-tooltip>
                        </div>
                        <div 
                        v-if="ownership"
                        class="mt-5 d-flex gap-7">
                            <v-btn
                                @click="multipleDelete()"
                                :color="selectingUser? 'dark' : 'accent'"
                                :class="selectingUser? 'white--text' : 'gradient'"
                                small
                            >{{ selectingUser? 'Reset All Selected' : 'Remove Assigned?'}}</v-btn>
                            <v-btn
                                v-if="selectedUsers.length"
                                @click="removeCollaborator()"
                                color="accent"
                                class="gradient"
                                small
                            >Remove Selected </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _uniqBy from 'lodash/uniqBy'
import db from '@/firebase/init'
import 'animate.css'


export default {
    data () {
        return {
            users: [],
            waitingUsers: true,
            selectingUser: false,
            selectedUsers: [],
            ownership: false,
        }
    },

 computed: {
    unique: function () {
        return _uniqBy(this.users, 'userid')
    },

    ...mapState({
      collabDialog: state => state.collaborators.collabDialog,
      collaborators: state => state.collaborators.collaborators,
      deleteCollaborator: state => state.collaborators.deleteCollabFlag,
      deleteMessage: state => state.collaborators.deleteCollabMessage,
      deleteData: state => state.collaborators.deleteCollabData,
      status: state => state.collaborators.status,
      authUser: state => state.user.user,
    }),

    enableSelect() {
        return this.selectingUser ? 'animate__animated animate__headShake' : '';
    }
  },
  

/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
 methods: {
     ...mapActions('collaborators', [
      'deleteCollaboratorRecord'
    ]),

    close() {
        this.$store.commit('collaborators/showDialog', false)
        this.$store.commit('collaborators/setDeleteCollabFlag', false)
        this.users = []
        this.selectedUsers = []
        this.selectingUser = false
    },

    deleteConfirmed() {
        let collab = this.deleteData.user[0].collabData
        Promise.all([this.deleteCollaboratorRecord(collab)])
        .then(() => {
            this.close()
        })
    },

    deleteDeclined() {
        this.close()
    },

    multipleDelete() {
        this.selectingUser = !this.selectingUser
    },

    selectedUser(user) {
        if(this.selectingUser){
            let refElement = user.userid
            this.selectedUsers.push(user)
            this.$refs[refElement][0].classList.remove('d-none')
        }
    },

    unselectUser(user) {
        if(this.selectingUser){
            this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1)
            let refElement = user.userid
            this.$refs[refElement][0].classList.add('d-none')

        }
    },

    removeCollaborator() {
        let hasError = false
        if(this.selectedUsers.length){
            this.selectedUsers.forEach(user => {
                Promise.all([this.deleteCollaboratorRecord(user.collabData)])
                .then(() => {
                    hasError = false
                })
                .catch(error => {
                    hasError = true
                    console.log(error)
                })
            })

            if(!hasError){
                let remainingCollabs = []
                this.selectedUsers.forEach(users => {
                    remainingCollabs.push(users.collabData)
                })
                this.$store.commit('collaborators/storeDeletedCollabs', {
                    collabs: remainingCollabs
                    })
                this.close()
            }
        }
    },

    getAuthUser(projectRef) {
        let projectid = projectRef.parent.path.split('/')
        if(projectid[1] != ''){
            db.collection('projects').doc(projectid[1])
            .get()
            .then(doc => {
                if (doc.exists) {
                    let data = doc.data()
                    data.id = doc.id
                    data.ref = doc.ref
                    if(data.owner === this.authUser.userid || this.authUser.role === 'admin'){
                        this.ownership = true
                    }else{
                        this.ownership = false
                    }
                }
            })
            .catch(error => {
                this.$store.dispatch('showError', error.message, { root: true })
            })
        }
        
    }
 },

  created() {
    
  }
}
</script>